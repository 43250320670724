import { Banner } from "@/ui/Banner";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import {
  // Button,
  Col,
  Row,
  // Stack
} from "react-bootstrap";
import { DynamicHtml } from "@/ui/DynamicHtml";
// import clsx from "classnames";
// import classes from "@/components/WorkForUsPage/WorkForUsPageBannerSection/styles.module.scss";
// import ArrowRight from "@/assets/ArrowRight.svg";
// import { getSpecialisms } from "@/functions/getSpecialisms";
// import { useLink } from "@/hooks/useLink";

export const HomePageBannerSection = ({ className }) => {
  const locale = useLocale();
  // const link = useLink();
  // const specialisms = getSpecialisms();

  return (
    <Banner
      className={className}
      title={<DynamicText tag="h1" path={`page.home.banner.title.${locale}`} />}
      image={{ path: "page.home.banner.image", src: "/assets/banners/home.png" }}
    >
      <Row>
        <Col md={6}>
          <DynamicHtml path={`page.home.banner.text.${locale}`} />
        </Col>
        {/*<Col xs={12}>*/}
        {/*  <div className={clsx(classes.buttons, "bg-white-50 shadow p-2")}>*/}
        {/*    <Stack className="flex-row flex-wrap flex-md-nowrap align-items-center flex-grow-0" gap={2}>*/}
        {/*      <DynamicText path={`page.home.banner.links.title.${locale}`} tag="h5" className="mb-0 px-2" />*/}
        {/*      {specialisms.map((i) => (*/}
        {/*        <Button key={i.id} href={link(`vacatures.${i.url_slug}`)} variant="primary">*/}
        {/*          <span className="me-2">{i.title}</span>*/}
        {/*          <ArrowRight width="14" height="12" />*/}
        {/*        </Button>*/}
        {/*      ))}*/}
        {/*    </Stack>*/}
        {/*  </div>*/}
        {/*</Col>*/}
      </Row>
    </Banner>
  );
};
