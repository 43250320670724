import { useLocale } from "@/hooks/useLocale";
import { SeriousAboutTalent } from "@/ui/SeriousAboutTalent";
import Image from "@/ui/Image";
import { Col } from "react-bootstrap";
import YoutubeVideoModal from "@/ui/YoutubeVideoModal";
import { getGlobal } from "@/functions/getGlobal";
import { stripTags } from "@/functions/stripTags";
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import React from "react";

export const HomePageContentSection = ({ className }) => {
  const locale = useLocale();
  const global = getGlobal();

  return (
    <SeriousAboutTalent
      className={className}
      tag={`page.home.content.tag.${locale}`}
      title={`page.home.content.title.${locale}`}
      text={`page.home.content.text.${locale}`}
      details={`page.home.content.details.${locale}`}
    >
      <Col xs={12} lg={6}>
        {/* START: CMS Tools editable fix */}
        <SourceFlowText
          global={global}
          path={`page.home.content.video.${locale}`}
          type="text"
          className="visually-hidden"
        />
        {/* END: CMS Tools editable fix */}
        <YoutubeVideoModal video_embed_url={stripTags(global[`page.home.content.video.${locale}`]).trim()}>
          <div className="ratio ratio-4x3 mt-4">
            <Image
              img={{ path: "page.home.content.image", src: "/assets/home/content/1.jpg" }}
              size="622x"
              alt=""
              style={{ objectFit: "cover", objectPosition: "center right" }}
              className={"object-fit-cover"}
            />
          </div>
        </YoutubeVideoModal>
      </Col>
    </SeriousAboutTalent>
  );
};
