import clsx from "classnames";
import Image from "@/ui/Image";
import { TabList } from "@/ui/TabList";
import { getReasons } from "@/functions/getReasons";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import { cleanMarkup } from "@/functions/cleanMarkup";

export const HomePageReasonsSection = ({ className }) => {
  const items = getReasons();
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.tabs)}>
      <TabList
        title={{ path: "page.home.reasons.title" }}
        text={{ path: "page.home.reasons.text" }}
        items={items.map((i, k) => ({
          title: i[`title_${locale}`],
          content: (
            <div
              key={k}
              className={clsx(classes.tabs__content, "p-2 p-md-5 me-md-5 h-100", {
                "flex-column": !i.value || i.value?.length === 0,
                "flex-column flex-md-row": i.value > 0,
              })}
            >
              {i.icon && <Image img={i.icon} size="100x100" alt="" className="mb-4 mw-100" />}
              {i.value && (
                <div className="flex-shrink-0">
                  <svg
                    className="mb-4 mw-100"
                    width="169"
                    height="155"
                    viewBox="0 0 169 155"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <text
                      className={classes.tabs__svg__text}
                      x="50%"
                      y="50%"
                      dominantBaseline="middle"
                      textAnchor="middle"
                    >
                      {i.value}
                    </text>
                    <rect x="0.185547" y="131.582" width="168.722" height="6.53502" rx="2.97619" fill="#BECDED" />
                    <rect x="42" y="132" width="88" height="6" rx="2.97619" fill="white" />
                    <g clipPath="url(#clip0_363_6658)">
                      <circle
                        cx="126.965"
                        cy="136.079"
                        r="3.8616"
                        transform="rotate(-6.82349 126.965 136.079)"
                        fill="#213867"
                      />
                      <path
                        d="M127.987 144.632C132.996 144.033 136.571 139.486 135.971 134.477C135.735 132.505 134.872 130.773 133.62 129.419C133.597 129.386 133.589 129.347 133.563 129.315L126.431 120.969C126.101 120.582 125.598 120.386 125.093 120.447C124.588 120.507 124.146 120.816 123.916 121.27L118.955 131.063C118.937 131.1 118.939 131.14 118.924 131.178C118.026 132.789 117.596 134.676 117.832 136.648C118.432 141.656 122.979 145.231 127.987 144.632ZM126.54 132.539C128.21 132.34 129.725 133.531 129.925 135.201C130.125 136.87 128.933 138.386 127.264 138.586C125.594 138.786 124.079 137.594 123.879 135.924C123.679 134.255 124.871 132.739 126.54 132.539Z"
                        fill="white"
                      />
                    </g>
                    <g clipPath="url(#clip1_363_6658)">
                      <circle cx="45.6311" cy="134.849" r="3.8616" fill="#213867" />
                      <path
                        d="M50.2386 126.977C45.87 124.455 40.2832 125.952 37.761 130.32C36.7679 132.04 36.4205 133.944 36.6116 135.778C36.6105 135.819 36.5931 135.855 36.5951 135.896L37.3017 146.851C37.3344 147.359 37.6193 147.817 38.0597 148.071C38.5 148.325 39.0391 148.343 39.4955 148.118L49.3362 143.252C49.3728 143.233 49.395 143.2 49.4297 143.179C51.1135 142.427 52.5887 141.174 53.5819 139.454C56.1041 135.086 54.6071 129.499 50.2386 126.977ZM44.1491 137.524C42.6929 136.683 42.1939 134.821 43.0347 133.365C43.8754 131.909 45.7377 131.41 47.1938 132.25C48.65 133.091 49.149 134.953 48.3083 136.41C47.4676 137.866 45.6053 138.365 44.1491 137.524Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_363_6658">
                        <rect
                          width="24.3578"
                          height="24.3578"
                          fill="white"
                          transform="translate(140.08 143.185) rotate(173.177)"
                        />
                      </clipPath>
                      <clipPath id="clip1_363_6658">
                        <rect
                          width="24.3578"
                          height="24.3578"
                          fill="white"
                          transform="translate(39.6914 120.888) rotate(30)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
              <div className="mx-md-5" dangerouslySetInnerHTML={{ __html: cleanMarkup(i[`text_${locale}`]) }} />
            </div>
          ),
        }))}
      />
    </div>
  );
};
